import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import config from "../config";
import { useNavigate } from 'react-router-dom';
const baseUrl = config.apiBaseUrl;

const Reset = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [token, setToken] = useState('');
    const [tokenR, setTokenR] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const params = new URLSearchParams(hash);
        const accessToken = params.get('access_token');
        const refreshToken = params.get('refresh_token');
        setToken(accessToken);
        setTokenR(refreshToken);
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        const formData = {
            Email: username,
            Password: password,
            Token: token,
            TokenR: tokenR,
            Type: 'recovery'
        };

        fetch(`${baseUrl}/api/users/resetuserpassword`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Reset failed');
                }
            })
            .then(data => {
                console.log('Success:', data);
                setSuccess('Password reset successfully. Redirecting to login...');
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Reset is invalid');
            });
    }

    return (
        <div>
            <Typography variant="h2">Forgotten Password</Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
            >
                <Typography variant="h4" gutterBottom>Request password reset</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                {success && <Alert severity="success">{success}</Alert>}
                <TextField
                    label="Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    fullWidth
                />
                <Button type="submit" variant="contained" color="primary">Reset</Button>
            </Box>
        </div>
    );
}

export default Reset;
