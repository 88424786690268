import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid } from '@mui/material';
import config from "../config";
import Helper from "../helpers";

const baseUrl = config.apiBaseUrl;
const AdminVenueManagement = () => {
  const [venue, setVenue] = useState({
    shortCode: '',
    name: '',
    addressId: '',
    postCode: ''
  });
  const [address, setAddress] = useState({
    addressLine1: '',
    addressLine2: '',
    addressCity: 't',
    addressPostcode: 'e'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenue({ ...venue, [name]: value });
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
    };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await Helper.getCookie('wh-jwt');
      const response = await fetch(`${baseUrl}/api/main/venues`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({...venue,...address})
      });

      if (response.ok) {
        alert('Venue added successfully');
      } else {
        console.error('There was an error adding the venue!', response.statusText);
      }
    } catch (error) {
      console.error('There was an error adding the venue!', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Add Venue</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
                label="Name"
                name="name"
                value={venue.name}
                onChange={handleChange}
                fullWidth
                required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Short Code"
              name="shortCode"
              value={venue.shortCode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Post Code"
              name="postCode"
              value={venue.postCode}
              onChange={handleChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              label="Address Line 1"
              name="addressLine1"
              value={address.addressLine1}
              onChange={handleAddressChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                label="Address Line 2"
                name="addressLine2"
                value={address.addressLine2}
                onChange={handleAddressChange}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                label="Address City"
                name="addressCity"
                value={address.addressCity}
                onChange={handleAddressChange}
                fullWidth
                required
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
                label="Address Postcode"
                name="addressPostcode"
                value={address.addressPostcode}
                onChange={handleAddressChange}
                fullWidth
                required
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">Add Venue</Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AdminVenueManagement;
