import React, { useState, useEffect } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { TextField, Button, Box, Typography, Alert, CssBaseline } from '@mui/material';
import config from "../config";
import Helper from "../helpers";
const baseUrl = config.apiBaseUrl;

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in and if the token is not expired
    const checkLoginStatus = async () => {
      const token = await Helper.getCookie('wh-jwt');
      if (token) {
        const tokenData = Helper.parseJwt(token);
        const currentTime = Date.now() / 1000;

        if (tokenData.exp > currentTime) {
          navigate('/dashboard');
        } else {
          // Token has expired, delete it
          await Helper.deleteCookie('wh-jwt');
          await Helper.deleteCookie('wh.user.email');
          await Helper.deleteCookie('wh.user.id');
        }
      }
    };

    checkLoginStatus();
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setError('');

    const formData = {
      Email: username,
      Password: password
    };

    fetch(`${baseUrl}/api/users/signin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to log in');
        }
      })
      .then(data => {
        var tokenData = Helper.parseJwt(data.token);

        Helper.setCookie('wh.user.email', tokenData.email, 5);
        Helper.setCookie('wh.user.id', tokenData.sub,5);

        if (tokenData.venues) {
          tokenData.venues.forEach(venue => {
            Helper.setCookie(`wh.venue.${venue.VenueShortCode}`, venue.VenueShortCode,5);
          });
        }

        if (tokenData.admin_venues) {
          tokenData.admin_venues.forEach(adminVenue => {
            Helper.setCookie(`wh.admin_venue.${adminVenue.VenueShortCode}`, adminVenue.VenueShortCode,5);
          });
        }

        Helper.setCookie('wh-jwt',data.token,5);

        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Error:', error);
        setError('Invalid username or password. Please try again.');
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
    >
      <CssBaseline />
      <Typography variant="h4" gutterBottom>Login</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        label="Email"
        value={username}
        type="email"
        onChange={(e) => setUsername(e.target.value)}
        required
        fullWidth
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary">Login</Button>
      <Link to={'/forgotten-password'}>Forgot password?</Link>
    </Box>
  );
};

export default Login;
