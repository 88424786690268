import React, {useEffect, useState} from 'react';
import {Container, Typography, Paper, TextField, Button} from '@mui/material';
import Grid from "@mui/material/Grid2";
import config from "../config";
import Helper from "../helpers";
const baseUrl = config.apiBaseUrl;

const AdminHome = () => {

  const [key, setKey] = useState("");

  useEffect(() => {
    //get teh key from local storage
    localStorage.key("");
  }, []);

  const handleSubmit = async () => {
    const token = await Helper.getCookie('wh-jwt');
    //X-admin-exchange-key: {key}
    fetch(`${baseUrl}/api/admin/merchants`, {
      method: 'GET',
      headers: {
        'X-admin-exchange-key': key,
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed ');
        }
    ).then(data => {
      console.log('Success:', data);
    }).catch(error => {
      console.error('Error:', error);
    });
  }

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      <TextField
          label="Unlock Key"
          value={key}
          type="text"
          onChange={(e) => setKey(e.target.value)}
          required
          fullWidth
      />
      <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>Unlock</Button>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Upcoming Sessions</Typography>
            <Typography variant="body1">Display upcoming sessions here</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>New Members</Typography>
            <Typography variant="body1">Display new members here</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Member Counts</Typography>
            <Typography variant="body1">Display member counts here </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <Paper style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Session Bookings Summary</Typography>
            <Typography variant="body1">Display session bookings summary here </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminHome;
