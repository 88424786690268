import {useEffect, useState} from "react";
import { Button} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import * as React from "react";
import PopupModelWrapper from "../components/PopupModelWrapper";
import AddSession from "../components/AddSession";
import Helper from "../helpers";
import {useNavigate} from "react-router-dom";

const SessionManagement = () => {
        const [openAddSessionModal, setOpenAddSessionModal] = useState(false);
        // const [openManageSessionsModal, setOpenManageSessionsModal] = useState(false);
        // const [sessions, setSessions] = useState([]);
        // const [sessionsName, setSessionsName] = useState("");
        // const [sessionsDate, setSessionDate] = useState(null);
        // const [sessionsTime, setSessionTime] = useState(null);
        // const [sessionMaxLimit, setSessionMaxLimit] = useState(1);
        const [venueKeyOptions, setVenueKeyOptions] = useState([]);
        const [currentVenueKey, setCurrentVenueKey] = useState("");
        const navigate = useNavigate();
        const handleOpenAddSessionModal = () => setOpenAddSessionModal(true);
        const handleCloseAddSessionModal = () => setOpenAddSessionModal(false);

        const handleAddManySession = () => {
            navigate('/dashboard/addmanysession');
        }
        // const handleOpenManageSessionsModal = () => setOpenManageSessionsModal(true);
        // const handleCloseManageSessionsModal = () => setOpenManageSessionsModal(false);
        //
        // const handleFetchSessions = async () => {
        //
        // };
        //
        // const handleViewSession = async (sessionId) => {
        //     // Implement API call to view session details
        // };
        //
        // const handleDeleteSession = async (sessionId) => {
        //     // Implement API call to delete session
        // };

    useEffect(() => {
        const initialize = async () => {
            await Helper.getAdminVenues(setCurrentVenueKey, setVenueKeyOptions);
        };

        initialize();
    }, [currentVenueKey]);

    return (
        <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
            <CardContent>

            <Button variant="contained" color="primary" sx={{
                margin: '3px'
            }} onClick={handleOpenAddSessionModal}>Add single Session</Button>
            <Button variant="contained" color="primary" sx={{
                margin: '3px'
            }} onClick={handleAddManySession}>Add many Sessions</Button>
            {/*<Button variant="contained" color="secondary" onClick={handleOpenManageSessionsModal}>Manage Sessions</Button>*/}
                {venueKeyOptions.length > 0 && (
                    <select
                        style={{ display: 'none' }}
                        value={currentVenueKey}
                        onChange={(e) => setCurrentVenueKey(e.target.value)}
                    >
                        {venueKeyOptions.map((venueKey) => (
                            <option key={venueKey} value={venueKey}>
                                {venueKey}
                            </option>
                        ))}
                    </select>
                )}
            <PopupModelWrapper isOpen = {openAddSessionModal} handleClose = {handleCloseAddSessionModal}  ModelContent={AddSession} Values={{venue : {key : currentVenueKey}}} />

            </CardContent>
        </Card>
    );
};

export default SessionManagement;
