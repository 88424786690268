import React from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

const InviteLink = ({ venueShortCode, sessionKey }) => {

    const generateInviteLink = (venueShortCode, sessionKey) => {
        const baseUrl = window.location.origin;
        return `${baseUrl}/create-account?venue-key=${venueShortCode}&ogt=${sessionKey}`;
    };

  const inviteLink = generateInviteLink(venueShortCode, sessionKey);

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
  };

  return (
    <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px'}}>
      <Typography variant="h6">Invite Link</Typography>
      <TextField
        value={inviteLink}
        fullWidth
        InputProps={{
          readOnly: true,
        }}
        sx={{ my: 2 }}
      />
      <Button variant="contained" color="primary" startIcon={<ContentCopy />} onClick={handleCopy}>
        Copy Link
      </Button>
    </Box>
  );
};

export default InviteLink;
