import React from 'react';
import { useParams } from 'react-router-dom';
import GoCardlessUserImport from "../components/GoCardlessUserImport";

const ImportUsers = () => {
    const { provider } = useParams();

    return (
        <>
            {provider === 'gocardless' && <GoCardlessUserImport />}
            {/* Add other provider components as needed */}
        </>
    );
}

export default ImportUsers;
