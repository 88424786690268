import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NextUserSession from "../../components/NextUserSession";
import * as React from "react";
import {useEffect, useState} from "react";
import Helper from "../../helpers";

export default function NextBooked() {

    const [userId, setUserId] = useState(null);

    useEffect(() => {
            const getUserId = async () => {
                const user = await Helper.getUserId();
                setUserId(user);
            }
            getUserId();
    }, []);

    return (
        <Card variant="outlined" sx={{height: '100%', flexGrow: 1}}>
            <CardContent>
                {userId &&
                    <NextUserSession userId={userId} />
                }
            </CardContent>
        </Card>
    );
}
