/*This page will be about setting up and paying for the monthly membership fees*/
import React from 'react';
import { Container, Typography, TextField, Button } from '@mui/material';

const MembershipPayment = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Membership Payment</Typography>
      <TextField label="Card Number" fullWidth margin="normal" />
      <TextField label="Expiry Date" fullWidth margin="normal" />
      <TextField label="CVV" fullWidth margin="normal" />
      <Button variant="contained" color="primary">Pay Membership Fee</Button>
    </Container>
  );
};

export default MembershipPayment;