import React from 'react';
import { AppBar, Box, Button, Container, CssBaseline, Toolbar, Typography, createTheme, ThemeProvider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid2";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#00bcd4',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1d1d1d',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
    },
    typography: {
        h4: {
            fontWeight: 700,
        },
        h6: {
            fontWeight: 700,
        },
    },
});

const HeroContent = styled(Box)(({ theme }) => ({
    background: 'linear-gradient(135deg, #00bcd4 30%, #ff4081 90%)',
    padding: theme.spacing(8, 0, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 64px)',
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

const Section = styled(Container)(({ theme }) => ({
    padding: theme.spacing(8, 0),
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
}));

const FeatureGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(4),
}));

const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    textAlign: 'center',
}));

const FeatureIcon = styled('span')(({ theme }) => ({
    fontSize: '48px',
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
}));

function Home() {
    const navigate = useNavigate();

    const handleLearnMoreClick = () => {
        const element = document.getElementById('below-the-fold');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleGetStartedClick = () => {
        navigate('/waiting-list');
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
                <Toolbar sx={{ flexWrap: 'wrap' }}>
                    <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                        WorldsHighStreet
                    </Typography>
                    <Button href="/login" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>

            <HeroContent>
                <Container maxWidth="sm">
                    <Typography component="h1" variant="h2" align="center" gutterBottom>
                        Booking the future of gym sessions
                    </Typography>
                    <Typography variant="h5" align="center" paragraph>
                        Streamline your bookings, manage members, and boost your revenue.
                    </Typography>
                    <Box sx={{ mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={handleGetStartedClick} sx={{ mr: 2 }}>
                            Get Started
                        </Button>
                        <Button variant="outlined" color="primary" onClick={handleLearnMoreClick}>
                            Learn More
                        </Button>
                    </Box>
                </Container>
            </HeroContent>

            <Section id="below-the-fold" maxWidth="md">
                <SectionTitle variant="h4" align="center">
                    Private Membership Management
                </SectionTitle>
                <Typography variant="body1" align="center" paragraph>
                    Effortlessly manage your members, track their sessions, and provide personalized experiences. Our platform gives you the tools to cultivate a thriving community and foster lasting relationships.
                </Typography>
                <Grid container spacing={4} className={FeatureGrid}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Centralized Member Database
                            </Typography>
                            <Typography variant="body2" align="center">
                                Keep all your member information in one secure and organized place.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Automated Session Reminders
                            </Typography>
                            <Typography variant="body2" align="center">
                                Reduce no-shows and keep your members engaged with timely reminders.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Personalized Communication
                            </Typography>
                            <Typography variant="body2" align="center">
                                Send targeted messages and announcements to specific member groups.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                </Grid>
            </Section>

            <Section maxWidth="md">
                <SectionTitle variant="h4" align="center">
                    Seamless Session Booking
                </SectionTitle>
                <Typography variant="body1" align="center" paragraph>
                    Offer your members a convenient and flexible way to book sessions online. Our integrated payment system allows for easy payment processing and ensures a smooth experience for both you and your clients.
                </Typography>
                <Grid container spacing={4} className={FeatureGrid}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Flexible Scheduling
                            </Typography>
                            <Typography variant="body2" align="center">
                                Allow members to book sessions at their convenience, 24/7.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Integrated Payments
                            </Typography>
                            <Typography variant="body2" align="center">
                                Accept payments seamlessly through our secure platform or integrate with your preferred provider.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FeatureItem>
                            <FeatureIcon>{/* Icon */}</FeatureIcon>
                            <Typography variant="h6" align="center">
                                Session Tracking
                            </Typography>
                            <Typography variant="body2" align="center">
                                Monitor member attendance, track session history, and generate reports with ease.
                            </Typography>
                        </FeatureItem>
                    </Grid>
                </Grid>
            </Section>
        </ThemeProvider>
    );
}

export default Home;
