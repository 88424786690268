import React, { useEffect, useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import Helper from '../helpers';
import config from "../config";

const baseUrl = config.apiBaseUrl;

const GoCardlessComplete = () => {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const completeOAuth = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const state = params.get('state');

            if (!code || !state) {
                // Handle error: missing code or state
                setLoading(false);
                return;
            }

            const token = await Helper.getCookie('wh-jwt');
            const response = await fetch(`${baseUrl}/api/oauth/complete-cardless-admin?Code=${code}&State=${state}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            let outputMessage = "";
            if (response.ok) {
                // Redirect to the dashboard on successful completion
                outputMessage = "complete";
            } else {
                // Handle error: API request failed
                setLoading(false);
                outputMessage = "notcomplete";
            }

            navigate('/dashboard?gocardless=' + outputMessage);
        };

        completeOAuth();
    }, [location, navigate]);

    return (
        <div>
            <h1>Completing GoCardless OAuth...</h1>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default GoCardlessComplete;
