// src/theme.js
import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#00897b',
        },
        secondary: {
            main: '#ffee58',
        },
        background: {
            default: '#3f51b5',
        },
    },
});

/**/

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
  },
});

export { lightTheme, darkTheme };
