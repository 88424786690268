import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Button, Typography, Box } from '@mui/material';
import Helper from '../helpers';
import Grid from "@mui/material/Grid2";
import config from "../config";
import {useNavigate} from "react-router-dom";

const baseUrl = config.apiBaseUrl;

const PaymentProviderLinks = () => {
    const [providers, setProviders] = useState({
        gocardless: false,
        stripe: false,
        paypal: false
    });
    const [loading, setLoading] = useState(true);
    const [currentVenueKey, setCurrentVenueKey] = useState("");
    const [currentVenueKeyOptions, setCurrentVenueKeyOptions] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProvidersStatus = async () => {

            const token = await Helper.getCookie('wh-jwt');
            const response = await fetch(`${baseUrl}/api/paymentadmin/admin/${currentVenueKey}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            const providersStatus = {
                gocardless: data.some(provider => provider.name === 'GoCardless' && provider.enabled),
                stripe: data.some(provider => provider.name === 'Stripe' && provider.enabled),
                paypal: data.some(provider => provider.name === 'PayPal' && provider.enabled)
            };
            setProviders(providersStatus);
        };

        const getCurrentVenueKey = async () => {
            await Helper.getAdminVenues(setCurrentVenueKey, setCurrentVenueKeyOptions);
            setLoading(false);
        }

        getCurrentVenueKey().then(fetchProvidersStatus);
    }, [currentVenueKey]);

    const handleConnect = async (provider) => {
        const token = await Helper.getCookie('wh-jwt');
        const response = await fetch(`${baseUrl}/api/oauth/get-token-url/${currentVenueKey}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        if (data.authUrl) {
            window.location.href = data.authUrl;
        }
    };

    const handleDisconnect = (provider) => {
        // Handle disconnect logic
    };

    const handleImportUsers = (provider) => {
        navigate(`/dashboard/import-users/${provider}`);
    };

    const placeholderImageUrl = '/img/paymentProvider/placeholder.jpg'; // Define the placeholder image URL

    return (
        <Grid container spacing={2}>
            {currentVenueKeyOptions.length > 0 && (
                <select
                    style={{ display: 'none' }}
                    value={currentVenueKey}
                    onChange={(e) => setCurrentVenueKey(e.target.value)}
                >
                    {currentVenueKeyOptions.map((venueKey) => (
                        <option key={venueKey} value={venueKey}>
                            {venueKey}
                        </option>
                    ))}
                </select>
            )}
            {['gocardless', 'stripe', 'paypal'].map((provider) => (
                <Grid item xs={12} key={provider}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <CardMedia
                                        component="img"
                                        alt={`${provider} logo`}
                                        height="140"
                                        image={`/img/paymentProvider/${provider}.png`} // Assuming you have the logos in the public/images folder
                                        onError={(e) => e.target.src = placeholderImageUrl}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <Typography variant="h5" component="div">
                                        {provider.charAt(0).toUpperCase() + provider.slice(1)}
                                    </Typography>
                                    <Box mt={2}>
                                        {providers[provider] ? (
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => handleDisconnect(provider)}
                                                fullWidth
                                            >
                                                Disconnect
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={loading}
                                                onClick={() => handleConnect(provider)}
                                                fullWidth
                                            >
                                                Connect
                                            </Button>
                                        )}
                                    </Box>
                                    <Box mt={2}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            onClick={() => handleImportUsers(provider)}
                                            fullWidth
                                        >
                                            Import Users
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default PaymentProviderLinks;
