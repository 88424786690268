import {Alert, Box, Button, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import config from "../config";
import { useNavigate} from 'react-router-dom';
const baseUrl = config.apiBaseUrl;

const Forgotten = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');

        const formData = {
            Email: username
        };

        fetch(`${baseUrl}/api/users/reset`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Reset failed');
                }
            })
            .then(data => {
                console.log('Success:', data);

                navigate('/login');

            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Reset is invalid');
            });
    }
    return (
        <div>
            <Typography variant="h2" >Forgotten Password</Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
            >
                <Typography variant="h4" gutterBottom>Request password reset</Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <TextField
                    label="Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    fullWidth
                />
                <Button type="submit" variant="contained" color="primary">Reset</Button>
            </Box>
        </div>
    );
}

export default Forgotten;
