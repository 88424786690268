import React from 'react';
import { Container, Typography, Button } from '@mui/material';

const Checkout = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Checkout</Typography>
      <Typography variant="body1">Add form elements for payment details here </Typography>
      <Button variant="contained" color="primary">Pay Now</Button>
    </Container>
  );
};

export default Checkout;
