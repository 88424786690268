import {Button, Container, CssBaseline, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Link} from "react-router-dom";
import React from "react";

const AcitvateAccount = () => {
    return (
        <Container>
            <CssBaseline />
            <Typography variant="h2" gutterBottom>Thank you for creating an account</Typography>
            <Typography variant="h5" paragraph>
                Please active your account from the email we sent you.
            </Typography>
            <Grid container spacing={2}>
                <Grid item>
                    <Typography variant="h5" paragraph>
                        Already activated?
                    </Typography>
                    <Button variant="contained" color="primary" component={Link} to="/login">
                        Login
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

export default AcitvateAccount;
