import React, { useState } from 'react';
import {
    Container,
    TextField,
    Button,
    Box,
    Typography,
    CssBaseline,
    Slider,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Checkbox,
    ListItemText,
    AppBar, Toolbar, Alert, createTheme
} from '@mui/material';
import config from "../config";

const baseUrl = config.apiBaseUrl;

const paymentProviders = [
  'PayPal',
  'WorldPay',
  'Stripe',
  'GoCardLess',
  'Square',
  'Adyen',
  'Other'
];

const WaitingListForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [budget, setBudget] = useState([50, 200]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [otherProvider, setOtherProvider] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    const formData = {
      name,
      email,
      budget,
      selectedProviders,
      otherProvider: selectedProviders.includes('Other') ? otherProvider : null
    };

    fetch(`${baseUrl}/api/waitinglist`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to submit form');
        }
      })
      .then((data) => {
        setMessage('Form submitted successfully');
      })
      .catch((error) => {
        setError('Failed to submit form');
      });
  };

  const handleBudgetChange = (event, newValue) => {
    setBudget(newValue);
  };

  const handleProviderChange = (event) => {
    setSelectedProviders(event.target.value);
  };

    const theme = createTheme({
        palette: {
            mode: 'dark',
            primary: {
                main: '#00bcd4',
            },
            secondary: {
                main: '#ff4081',
            },
            background: {
                default: '#121212',
                paper: '#1d1d1d',
            },
            text: {
                primary: '#ffffff',
                secondary: '#b0bec5',
            },
        },
        typography: {
            h4: {
                fontWeight: 700,
            },
            h6: {
                fontWeight: 700,
            },
        },
    });

  return (
      <>
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
          <Toolbar sx={{ flexWrap: 'wrap' }}>
              <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                  WorldsHighStreet
              </Typography>
              <Button href="/login" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
                  Login
              </Button>
          </Toolbar>
      </AppBar>

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Join the Waiting List
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            name="name"
            autoComplete="name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Typography variant="body1" gutterBottom>
            What would you spend on a booking and session management system per month?
          </Typography>
          <Slider
            value={budget}
            onChange={handleBudgetChange}
            valueLabelDisplay="auto"
            min={0}
            max={500}
            step={10}
            marks={[
              { value: 0, label: '£0' },
              { value: 100, label: '£100' },
              { value: 200, label: '£200' },
              { value: 300, label: '£300' },
              { value: 400, label: '£400' },
              { value: 500, label: '£500' },
            ]}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="payment-providers-label">Payment Providers</InputLabel>
            <Select
              labelId="payment-providers-label"
              id="payment-providers"
              multiple
              value={selectedProviders}
              onChange={handleProviderChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {paymentProviders.map((provider) => (
                <MenuItem key={provider} value={provider}>
                  <Checkbox checked={selectedProviders.indexOf(provider) > -1} />
                  <ListItemText primary={provider} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedProviders.includes('Other') && (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="other-provider"
              label="Please specify other provider"
              name="other-provider"
              value={otherProvider}
              onChange={(e) => setOtherProvider(e.target.value)}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
          >
            Submit
          </Button>
          {message && <Alert severity="success" sx={{ mt: 2 }}>{message}</Alert>}
          {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        </Box>
      </Box>
    </Container>
      </>
  );
};

export default WaitingListForm;
