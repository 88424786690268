import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TokenCount from "../../components/ToeknCount";
import ListVenueUsers from "../../components/ListVeuneUsers";
import NextBooked from "./NextBooked";
import SessionManagement from "../../pages/SessionManagement";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import InviteLink from "../../components/InviteLink";
import Helper from "../../helpers";
import {useEffect} from "react";
import {CssBaseline} from "@mui/material";

export default function DashboardHome() {
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [venueOptions, setVenueOptions] = React.useState([]);
    const [currentVenueKey, setCurrentVenueKey] = React.useState("");
    // const [userId, setUserId] = React.useState(null);

    useEffect(() => {
        const checkAdmin = async () => {
            const admin = await Helper.userIsAdmin();
            await Helper.getAdminVenues(setCurrentVenueKey,setVenueOptions)
            setIsAdmin(admin);
        };
        // const getUserId = async () => {
        //     const user = await Helper.getUserId();
        //     setUserId(user);
        // }
        checkAdmin();
        // getUserId();
    }, []);

    return (
        <Box sx={{width: '100%', maxWidth: {sm: '100%', md: '1700px'}}}>
            <CssBaseline/>
            {/* cards */}
            {venueOptions && venueOptions.length > 0 && (
                <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                </Grid>
            )}
            <Typography component="h2" variant="h5" sx={{mb: 2}}>
                Welcome : {isAdmin ? "Admin" : "User"}
            </Typography>
            <Typography component="h2" variant="h5" sx={{mb: 2}}>
                Overview
            </Typography>
            <Grid
                container
                spacing={2}
                columns={12}
                sx={{mb: (theme) => theme.spacing(2)}}
            >
                {isAdmin &&
                    <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                        <SessionManagement/>
                    </Grid>
                }
                {isAdmin &&
                <Grid size={{xs: 12, sm: 6, lg: 3}}>
                    <Card sx={{height: '100%'}}>
                        <CardContent>ToDo : Flow for setup GoCardLess link
                        </CardContent>
                    </Card>
                </Grid>
                }
                <Grid size={{xs: 12, sm: 6, lg: 3}}>
                    <TokenCount/>
                </Grid>

                <Grid size={{xs: 12, sm: 6, lg: 3}}>
                    <NextBooked/>
                </Grid>
                {isAdmin &&
                <Grid container size={{xs: 12, sm: 11, lg: 11}}>

                            <InviteLink sessionKey={"sessionKeyIn"} venueShortCode={currentVenueKey} />

                </Grid>
                }
            </Grid>
            {/*{!isAdmin && userId &&*/}
            {/*<Grid container size={{xs: 12, sm: 6, lg: 3}}>*/}
            {/*    <NextUserSession userId={userId}/>*/}
            {/*</Grid>*/}
            {/*}*/}
            {isAdmin &&
            <Grid container size={{xs: 12, sm: 6, lg: 3}}>
                <ListVenueUsers/>
            </Grid>
            }

        </Box>
    );
}
