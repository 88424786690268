/*This component will be about show the user how many token they have left to use*/
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import config from "../config";
import {useEffect, useState} from "react";
import Helper from "../helpers";
import {CssBaseline} from "@mui/material";

const baseUrl = config.apiBaseUrl;
const TokenCount = () => {
    const [loading, setLoading] = useState(true);
    const [tokenCount, setToeknCount] = useState(0);
    const [userId, setUserId] = useState(null);
    const [userVenueKey, setUserVenueKey] = useState(null);

    useEffect(() => {
        const fetchToken = async () => {
            const getUserId = async () => {
                const user = await Helper.getUserId();
                setUserId(user);
            }

            const getUserTokens = async (uvk) => {
                const token = await Helper.getCookie('wh-jwt');
                await fetch(`${baseUrl}/api/users/token/${uvk}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    },
                    credentials: 'include'
                })
                    .then(response => response.json())
                    .then(data => {
                        setToeknCount(data.tokens);
                    })
            };

            const getUserVenueId = async () => {
                const venue = await Helper.getUserVenueId();
                setUserVenueKey(venue);
                await getUserTokens(venue);
            }

            await getUserId();
            await getUserVenueId();

            setLoading(false);
        };

        fetchToken();
    }, [userId,userVenueKey]);

    if(loading) {
        return <div id="overlay">Loading...</div>;
    }

    return tokenCount ? (
    <Card sx={{ height: '100%' }}>
        <CssBaseline/>
        <CardContent>
            <AccountBalanceWalletIcon /> : {tokenCount}
            <Typography
                component="h2"
                variant="subtitle2"
                gutterBottom
                sx={{ fontWeight: '600' }}
            >
                Token Count
            </Typography>
        </CardContent>
    </Card>
    ) : <></>;
}

export default TokenCount;
