import {Box, Modal} from "@mui/material";
import * as React from "react";


const PopupModelWrapper = (props) => {

    const { isOpen, handleClose, ModelContent, Values } = props;

    let modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Box sx={{ ...modalStyle }}>
                <ModelContent handleClose ={handleClose} Values={Values} />
            </Box>
        </Modal>
    )
}

export default PopupModelWrapper;
