/*This will be a checkout complete page this will be when an payment is sucesful it will show nesacery informationa obut the items brouhg I.e a session or other items*/
import React from 'react';
import { Container, Typography } from '@mui/material';

const CheckoutComplete = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Checkout Complete</Typography>
      <Typography variant="body1">Display information about the purchased items here</Typography>
    </Container>
  );
};

export default CheckoutComplete;
