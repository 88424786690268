import {
    Button,
    Card,
    CardContent,
    Fab,
    List,
    ListItem,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {format, parseISO, differenceInHours} from "date-fns";
import React, {useEffect, useMemo, useState} from "react";
import BookSession from "./BookSession";
import PopupModelWrapper from "./PopupModelWrapper";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import ConfirmCancelSession from "./ConfirmCancelSession";
import CallIcon from '@mui/icons-material/Call';
import ManageSession from "./ManageSession";
import Helper from "../helpers";
import Paper from "@mui/material/Paper";

const SessionView = (props) => {
    const {session, handleOpen, AdminView, Users} = props;
    // const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [isAdmin, setIsAdmin] = useState(false);
    const [sessionUsers, setSessionUsers] = useState(Users);
    const [openAddBookingModal, setOpenAddBookingModal] = useState(false);
    const [openCancelBookingModal, setOpenCancelBookingModal] = useState(false);
    const [openManageSessionModal, setOpenManageSessionModal] = useState(false);

    const handleCloseAddBookingModal = () => {
        setOpenAddBookingModal(false);

    }
    const handleCloseCancelBookingModal = () => {
        setOpenCancelBookingModal(false);
    }
    const handleCloseManageSessionModal = () => {
        setOpenManageSessionModal(false);
    }

    useMemo(() => {
        setSessionUsers(Users);
    }, [Users]);

    useEffect(() => {
        const checkAdmin = async () => {
            const admin = await Helper.userIsAdmin();
            setIsAdmin(admin);
        };
        checkAdmin();
    }, []);

    //const genericImageUrl = process.env.PUBLIC_URL + "/img/sessionGenerics/personal_session.jpeg";
    const genericImageUrl = process.env.PUBLIC_URL + "/img/insight_gym_long.jpg";

    return (
        <>
            <Card
                key={session.sessionId}
                sx={{
                    mb: 2,
                    borderRadius: 3,
                    boxShadow: 3,
                    overflow: "hidden",
                    backgroundImage: `url(${session.imageUrl || genericImageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "75% 50%",
                }}
            >
                <CardContent sx={{
                    backdropFilter : !session.imageUrl ? "grayscale(100%) opacity(0.9) saturate(80%)" : "none"
                }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={4} sx={{
                            padding: 1,
                            backgroundColor: "rgba(255, 255, 255, 0.8)",
                            borderRadius: 3,
                            color: "black",
                        }}>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                {session.title}
                            </Typography>
                            <Fab variant="extended" sx={{
                                margin: 1,
                            }} size="medium" color="success" disableRipple={true} disableFocusRipple={true}>
                                <AccessTimeIcon sx={{ mr: 1 }} />
                                {format(parseISO(session.startDate), "MMM dd hh:mm")}
                            </Fab>
                            <Typography variant="body2" color="text.priamry">
                                Places Taken: {session.seatsTaken} / {session.availableSeats}
                            </Typography>
                            <Typography variant="body2" color="text.priamry">
                                Length: {session.numberOfMinuets} minutes
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} sx={{ textAlign: "center", fontSize:{xs:11,sm:12}, width : {xs:'220px' }}} offset={{ md: 'auto' }}>
                            <List aria-label="actions">
                                {(!AdminView && session.sessionBookText !== "Booked") && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={(session.availableSeats - session.seatsTaken) === 0 || differenceInHours(new Date(session.startDate), new Date()) < 24}
                                            onClick={() => setOpenAddBookingModal(true)}
                                            sx={{
                                                borderRadius: 20,
                                                px: 4,
                                                fontWeight: "bold",
                                                backgroundColor: (session.availableSeats - session.seatsTaken) === 0 || differenceInHours(new Date(session.startDate), new Date()) < 24 ? 'rgba(0, 0, 0, 0.12)' : 'primary.main',
                                                color: (session.availableSeats - session.seatsTaken) === 0 || differenceInHours(new Date(session.startDate), new Date()) < 24 ? 'rgba(0, 0, 0, 0.26)' : 'white',
                                                '&.Mui-disabled': {
                                                    backgroundColor: 'rgb(94,94,94,0.9)',
                                                    color: 'rgb(243,241,241,0.9)',
                                                    opacity: 1 // Remove default opacity
                                                }
                                            }}
                                            endIcon={differenceInHours(new Date(session.startDate), new Date()) < 24 ? <CallIcon /> : <SendIcon />}
                                        >
                                            {(session.availableSeats - session.seatsTaken) === 0
                                                ? "Full"
                                                : differenceInHours(new Date(session.startDate), new Date()) < 24
                                                    ? "Call to Book"
                                                    : session.sessionBookText}
                                        </Button>
                                    </ListItem>
                                )}
                                {(!AdminView && session.sessionBookText === "Booked") && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={session.availableSeats === 0}
                                            onClick={() => setOpenCancelBookingModal(true)}
                                            sx={{
                                                borderRadius: 20,
                                                px: 4,
                                                fontWeight: "bold",
                                            }}
                                            endIcon={<CancelIcon />}
                                        >
                                            Cancel Session
                                        </Button>
                                    </ListItem>
                                )}
                                {isAdmin && (
                                    <ListItem>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size={"small"}
                                            onClick={() => handleOpen({ title: session.name, userType: "AddToken" })}
                                            sx={{
                                                borderRadius: 20,
                                                px: 4,
                                                fontWeight: "bold",
                                            }}
                                            endIcon={<CancelIcon />}
                                        >
                                            Manage Session
                                        </Button>
                                    </ListItem>
                                )}
                            </List>
                        </Grid>
                        {sessionUsers && sessionUsers.length > 0 && (
                        <Grid xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: {sx:440, sm : 550} }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User Name</TableCell>
                                            <TableCell align="right">Actions</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {sessionUsers.map((row) => (
                                            <TableRow
                                                key={row.userId}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid> )}
                        {/*{!isMobile && (*/}
                        {/*<Grid*/}
                        {/*    item*/}
                        {/*    xs={12}*/}
                        {/*    sm={4}*/}
                        {/*    sx={{*/}
                        {/*        display: "flex",*/}
                        {/*        justifyContent: "flex-end",*/}
                        {/*        width: { xs: "100%", sm: "300px" },*/}
                        {/*        aspectRatio: "3/1",*/}
                        {/*        objectFit: "cover",*/}
                        {/*        objectPosition: "top center",*/}
                        {/*        backgroundSize: "cover",*/}
                        {/*        backgroundPosition: "top center",*/}
                        {/*        filter: isMobile && !session.imageUrl ? "grayscale(100%) opacity(0.5) blur(10px)" : "none"*/}
                        {/*    }}*/}
                        {/*    offset={{ md: 'auto' }}*/}
                        {/*>*/}
                        {/*    {!isMobile && (*/}
                        {/*        <CardMedia*/}
                        {/*            component="img"*/}
                        {/*            image={session.imageUrl || genericImageUrl}*/}
                        {/*            alt={session.title}*/}
                        {/*            sx={{*/}
                        {/*                objectFit: "cover",*/}
                        {/*                width: "100%",*/}
                        {/*                maxHeight: "140px",*/}
                        {/*                filter: !session.imageUrl ? "grayscale(100%) opacity(0.5)" : "none"*/}
                        {/*            }}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</Grid>*/}
                        {/*    )}*/}
                    </Grid>
                </CardContent>
            </Card>

            <PopupModelWrapper isOpen={openAddBookingModal} handleClose={handleCloseAddBookingModal} ModelContent={BookSession} Values={{ title: session.title, sessionId: session.sessionId, sessionCost: session.sessionCost }} />
            <PopupModelWrapper isOpen={openCancelBookingModal} handleClose={handleCloseCancelBookingModal} ModelContent={ConfirmCancelSession} Values={{ title: session.title, sessionId: session.sessionId }} />
            <PopupModelWrapper isOpen={openManageSessionModal} handleClose={handleCloseManageSessionModal} ModelContent={ManageSession} Values={{ title: session.title, sessionId: session.sessionId, sessionDateTime: session.startDate }} />
        </>
    );
}

export default SessionView;
