import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Avatar, CardMedia} from "@mui/material";

const VenueInformation = () => {
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', mb: 2,
            borderRadius: 3, // Rounded corners
            boxShadow: 3, }}>

            <CardContent>
                <Avatar sx={{
                    bgcolor: "#121212",
                    minWidth :200,
                    minHeight: 200,
                    // width: {sx:200, sm : 250}, height:  200,
                }} variant="rounded">
            <CardMedia alt="Insight Gym Logo"
                          component="img"
                       image={'/img/venueLogos/insight-gym-logo.jpg'}
                 sx={{ margin: '4px 25px', display: "flex",
                     justifyContent: "flex-end",
                     aspectRatio: "2/1",
                     objectFit: "cover",
                     objectPosition : "center center"}}/>

                </Avatar>
            </CardContent>
            <CardContent>
                <h2>Insight Gym</h2>
                <p></p>
                <p>Phone : </p>
                <p>Email : </p>
                <p>Website : </p>

            </CardContent>
        </Card>
    );
}

export default VenueInformation;
