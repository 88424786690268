import React, { useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, TextField, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { LocalizationProvider, TimePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import Grid from "@mui/material/Grid2";
import Helper from "../helpers";
import config from '../config';

const baseUrl = config.apiBaseUrl;
const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const BulkSession = () => {
    const [selectedDays, setSelectedDays] = useState([]);
    const [sessions, setSessions] = useState([]);
    const [sessionTimes, setSessionTimes] = useState([dayjs().add(1, 'hour')]);
    const [sessionLength, setSessionLength] = useState(1);
    const [sessionSeats, setSessionSeats] = useState(1);
    const [sessionTokenCost, setSessionTokenCost] = useState(1);
    const [startDate, setStartDate] = useState(dayjs());
    const [numWeeks, setNumWeeks] = useState(1);
    const [sessionName, setSessionName] = useState("");
    const [venueKeyOptions, setVenueKeyOptions] = useState([]);
    const [currentVenueKey, setCurrentVenueKey] = useState("");

    const handleDayChange = (day) => {
        setSelectedDays(prev => prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]);
    };

    const handleAddSession = () => {
        if (sessions.length >= 1000) {
            alert('You can only add up to 1000 sessions at a time.');
            return;
        }

        const newSessions = [];
        for (let i = 0; i < numWeeks; i++) {
            selectedDays.forEach(day => {
                sessionTimes.forEach(time => {
                    const sessionDate = startDate.add(i, 'week').day(daysOfWeek.indexOf(day) + 1);
                    newSessions.push({
                        day,
                        date: sessionDate,
                        time,
                        length: sessionLength,
                        seats: sessionSeats,
                        tokenCost: sessionTokenCost,
                        name: sessionName,
                    });
                });
            });
        }

        setSessions([...sessions, ...newSessions]);
    };

    const handleRemoveSession = (index) => {
        const updatedSessions = sessions.filter((_, i) => i !== index);
        setSessions(updatedSessions);
    };

    const handleAddTime = () => {
        setSessionTimes([...sessionTimes, dayjs().add(1, 'hour')]);
    };

    const handleRemoveTime = (index) => {
        const updatedTimes = sessionTimes.filter((_, i) => i !== index);
        setSessionTimes(updatedTimes);
    };

    const handleClose = () => {
        setSessions([]);
        setSessionTimes([dayjs().add(1, 'hour')]);
        setSessionLength(1);
        setSessionSeats(1);
        setSessionTokenCost(1);
        setStartDate(dayjs());
        setNumWeeks(1);
        setSessionName("");
    };
    const handleSubmit = async () => {
        const sessionData =  sessions.map(session => ({
            startDate: `${session.date.format('YYYY-MM-DD')}T${session.time.format('HH:mm:00')}Z`,
            availableSeats: session.seats,
            numberOfMinuets: session.length * 60,
            name: session.name,
            tokenCost: session.tokenCost,
        }));
        const token = await Helper.getCookie('wh-jwt');
//AddSession
        fetch(`${baseUrl}/api/Sessions/AddSession/${currentVenueKey}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(sessionData),
        })
            .then(response => {
                if (response.ok) {
                    alert('Sessions added successfully');
                    handleClose();
                } else {
                    alert('Failed to add sessions');
                }
            })
            .catch(error => {
                console.error('Error adding sessions', error);
                alert('Failed to add sessions');
            });
    };

    Helper.getAdminVenues(setCurrentVenueKey, setVenueKeyOptions);

    return (
        <Box sx={{
            backgroundColor: '#272727',
            padding: '5px',
            borderRadius: '5px'
        }}
             component="form">
            <Typography variant="h6" component="h2">Bulk Add Sessions</Typography>
            {venueKeyOptions.length > 0 && (
                <select
                    style={{ display: 'none' }}
                    value={currentVenueKey}
                    onChange={(e) => setCurrentVenueKey(e.target.value)}
                >
                    {venueKeyOptions.map((venueKey) => (
                        <option key={venueKey} value={venueKey}>
                            {venueKey}
                        </option>
                    ))}
                </select>
            )}
            <Box mt={2}>
                {daysOfWeek.map(day => (
                    <FormControlLabel
                        key={day}
                        control={<Checkbox checked={selectedDays.includes(day)}
                                           onChange={() => handleDayChange(day)}/>}
                        label={day}
                    />
                ))}
            </Box>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Grid container spacing={2} sx={{
                    mt: 2,
                    marginBottom: '10px'
                }}>
                    <Grid size={3}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD/MM/YYYY"
                            onChange={(newValue) => setStartDate(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                        />
                    </Grid>
                    <Grid size={4}>
                        {sessionTimes.map((time, index) => (
                            <Box key={index} display="flex" alignItems="center">
                                <TimePicker
                                    label={`Session Start Time ${index + 1}`}
                                    value={time}
                                    onChange={(newValue) => {
                                        const updatedTimes = [...sessionTimes];
                                        updatedTimes[index] = newValue;
                                        setSessionTimes(updatedTimes);
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                                    ampm={false}
                                />
                                <IconButton onClick={() => handleRemoveTime(index)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                        ))}
                        <Button variant="contained" color="primary" onClick={handleAddTime} startIcon={<AddIcon/>}
                                sx={{mt: 2}}>
                            Add Another Time
                        </Button>
                    </Grid>
                </Grid>
            </LocalizationProvider>
    <Box sx={{
        margin: '5px',
    }}>
        <TextField
            label="Session Name"
            type={"text"}
            value={sessionName}
            onChange={(e) => setSessionName(e.target.value)}
            margin="normal"
            fullWidth
            sx={{margin:'6px', width: '102ch'}}
        />
        <TextField
            label="Number of Weeks"
            type="number"
            value={numWeeks}
            onChange={(e) => setNumWeeks(e.target.value)}
            margin="normal"
            sx={{margin:'3px', width: '25ch'}}
        />
        <TextField
            label="Session Length (hours)"
            type="number"
            value={sessionLength}
            onChange={(e) => setSessionLength(e.target.value)}
            margin="normal"
            sx={{margin:'3px', width: '25ch'}}
        />
        <TextField
            label="Available Seats for Session"
            type="number"
            value={sessionSeats}
            onChange={(e) => setSessionSeats(e.target.value)}
            margin="normal"
            sx={{margin:'3px', width: '25ch'}}
        />
        <TextField
            label="Session Token Cost"
            type="number"
            value={sessionTokenCost}
            onChange={(e) => setSessionTokenCost(e.target.value)}
            margin="normal"
            sx={{margin:'3px', width: '25ch'}}
        />
    </Box>
    <Button variant="contained" color="primary" onClick={handleAddSession} sx={{mt: 2, ml: 2}}>
        Plan Session
    </Button>
    <Button variant="contained" color="success" onClick={handleSubmit} sx={{mt: 2, ml: 2}}>
        Submit All Sessions
    </Button>
    <Box sx={{ mt: 2 }}>
        <Typography variant="h6">Summary</Typography>
        <Typography variant="body1">Number of Sessions: {sessions.length}</Typography>
        <Typography variant="body1">Start Times: {sessionTimes.map(time => time.format('HH:mm')).join(', ')}</Typography>
    </Box>
    <TableContainer component={Paper} sx={{mt: 2}}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Length (hours)</TableCell>
                    <TableCell>Seats</TableCell>
                    <TableCell>Token Cost</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sessions.map((session, index) => (
                    <TableRow key={index}>
                        <TableCell>{session.day}</TableCell>
                        <TableCell>{session.date.format('YYYY-MM-DD')}</TableCell>
                        <TableCell>{session.time.format('HH:mm')}</TableCell>
                        <TableCell>{session.length}</TableCell>
                        <TableCell>{session.seats}</TableCell>
                        <TableCell>{session.tokenCost}</TableCell>
                        <TableCell>
                            <IconButton onClick={() => handleRemoveSession(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
</Box>
)
    ;
};

export default BulkSession;
