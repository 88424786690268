import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function MembershipOptionCard({membershipLevel}) {
    if (!membershipLevel) {
        return null;
    }
    let leveltext = "";
    let levelDescriptionParagraphText = "";
    //ToDo : Get these subscript fromt he API

    switch (membershipLevel) {
        case "payg":
            leveltext = "Pay as you go";
            levelDescriptionParagraphText = "Pay as you go membership level is free and you can book sessions as you go and pay the standard session fee.";
            break;
        case "level-1":
            leveltext = "Silver";
            levelDescriptionParagraphText = "Silver membership level is £297.99 and you can book 2 sessions a week. Additional sessions can be booked at the with a 5% session fee discount.";
            break;
        case "level-2":
            leveltext = "Gold";
            levelDescriptionParagraphText = "Gold membership level is £390.99 and you can book 3 sessions a week. Additional sessions can be booked at the with a 10% session fee discount.";
            break;
        case "level-3":
            leveltext = "Platinum";
            levelDescriptionParagraphText = "Platinum membership level is £578.99 and you can book 5 sessions a week.  Additional sessions can be booked at the with a 15% session fee discount.";
            break;
        default:
            leveltext = "Pay as you go";
            levelDescriptionParagraphText = "Pay as you go membership level is free and you can book sessions as you go and pay the standard session fee.";
            break;
    }
    return (
        <Card sx={{minWidth: 275}}>
            <CardContent>
                <Typography gutterBottom sx={{color: 'text.secondary', fontSize: 14}}>
                    Level Benefits :
                </Typography>
                <Typography variant="h5" component="div">
                    {leveltext}
                </Typography>
                <Typography sx={{color: 'text.secondary', mb: 1.5}}>Level Benefits</Typography>
                <Typography variant="body2">
                    {levelDescriptionParagraphText}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>

    );
}
