import {Typography} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

export default function NextSessionContent(nextSession) {
    return (
        <>
            <Typography variant="h5" gutterBottom>
                Next Session
            </Typography>
            {nextSession === null ? (
                    <>
                        <Typography variant="body1">
                            <strong>Name:</strong> {nextSession.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Start Date:</strong> {new Date(nextSession.startDate).toLocaleString()}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Duration:</strong> {nextSession.timeSpan}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Available Seats:</strong> {nextSession.availableSeats}
                        </Typography>
                        <Typography variant="body1">
                            <strong>Seats Taken:</strong> {nextSession.seatsTaken}
                        </Typography>
                    </>
                ) :
                (
                    <>
                        <Typography>No upcoming sessions found.</Typography>
                        <Button variant="contained" color="primary" component={Link} to="/dashboard/sessions">Find Session</Button>
                    </>
                )
            }

        </>
    );
}
