/*this will be the users home to show all teh realivent hisna bout there account next session all bookings vata stas the prediect temp for the new few days*/
import React from 'react';
import {Container, styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';
import NextUserSession from "../components/NextUserSession";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const UserHome = () => {
  return (
    <Container>
        <Grid container spacing={2}>
            <Grid size={8}>
                <NextUserSession userId={1}/>
            </Grid>
            <Grid size={4}>
                <Item>size=4</Item>
            </Grid>
            <Grid size={4}>
                <Item>size=4</Item>
            </Grid>
            <Grid size={8}>
                <Item>size=8</Item>
            </Grid>
        </Grid>
    </Container>
  );
};

export default UserHome;
