import React from 'react';
import { Container, Typography, Button } from '@mui/material';

const Session = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>Session Details</Typography>
      <Typography variant="body1">Display detailed information about the session here </Typography>
      <Button variant="contained" color="primary">Book Now</Button>
      <Button variant="contained" color="secondary">Join Waiting List</Button>
    </Container>
  );
};

export default Session;
