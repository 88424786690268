/*This page will be about showing the current membership level option to upgrade or cancel*/
import React from 'react';
import {Container, Typography, Button, InputLabel, FormControl, Select, MenuItem} from '@mui/material';
import AccountSetupStepper from "../components/AccountSetupStepper";
import MembershipOptionCard from "../components/MembershipOptionCard";

const Membership = () => {
    const [membershipLevel, setMembershipLevel] = React.useState('');
    const [membershipCompleteText, setMembershipComplete] = React.useState('Please Select Membership Level');
    const handleChange = (event) => {
        setMembershipLevel(event.target.value);

        const selectedLevel = event.target.value;
        setMembershipLevel(selectedLevel);
        if (selectedLevel === 'payg') {
            setMembershipComplete('Complete Membership');
        } else {
            setMembershipComplete('Pay and Complete Membership');
        }
    };
    
    return (
        <Container>
            <AccountSetupStepper currentStep={1}/>
                <Typography variant="h4" gutterBottom>Membership</Typography>
            <form>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Memebership Level</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={membershipLevel}
                        label="Memebership Level"
                        onChange={handleChange}
                    >
                        <MenuItem value={"payg"}>Pay As You Go (Free)</MenuItem>
                        <MenuItem value={"level-1"}>2 Session a week (£297.99)</MenuItem>
                        <MenuItem value={"level-2"}>3 Session a Week Plus (£390.99)</MenuItem>
                        <MenuItem value={"level-3"}>5 Session a Week Plus Plus (£578.99)</MenuItem>
                    </Select>
                    
                    <MembershipOptionCard membershipLevel={membershipLevel}/>
                    
                <Button margin="normal" variant="contained" color="primary" sx={{margin:"10px"}} >{membershipCompleteText}</Button>
                    https://developer.gocardless.com/direct-debit/taking-subscription-payments
                </FormControl>
                
            </form>
        </Container>
    );
};

export default Membership;