/*This is will be the form for creating and acocunt i want this to be a formt hta a admin can use to help other screate and acocutn (ie it will genetaret a temporty password that a user has to update on first login) or that a user cna use themesefls to create and accoutna dn they can shouces there own password*/
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Container,
    Typography,
    TextField,
    Button, Alert, CssBaseline,
} from '@mui/material';
import AccountSetupStepper from "../components/AccountSetupStepper";
import config from "../config";
import './CreateAccount.css';
const baseUrl = config.apiBaseUrl;

const CreateAccount = () => {
    const location = useLocation();
    const [venueKey, setVenueKey] = useState('');
    const [oiriginTrace, setOiriginTrace] = useState('');
    const [emailValidationText, setEmailValidationText] = useState('');
    const [passwordValidationText, setPasswordValidationText] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const key = params.get('venue-key');
        const keyOriginKey = params.get('ogt');
        if (key) {
            setVenueKey(key);
        }
        if(keyOriginKey){
            setOiriginTrace(keyOriginKey);
        }

    }, [location]);

    function resetValidationText() {
        setEmailValidationText('');
        setPasswordValidationText('');
    }

    function handleSubmit(event) {
        //ToDo : confirm password match
        //ToDo : Send form to api securely
        setError("");
        event.preventDefault();
        let emailPattern = /^[^@]+@[^@]+\.[^@]+$/;
        const emailInput = document.getElementById('email');
        const passwordInput = document.getElementById('pwd');
        const confirmPasswordInput = document.getElementById('confirmPwd');

        if (!emailPattern.test(emailInput.value)) {
            setEmailValidationText('Please enter a valid email address');
            emailInput.reportValidity();
            return;
        }

        if (!matchValue(passwordInput.value, confirmPasswordInput.value)) {
            setPasswordValidationText('Passwords do not match');
            confirmPasswordInput.reportValidity();
            return;
        }

        if(passwordInput.value.length < 8){
            setPasswordValidationText('Password must be at least 8 characters');
            confirmPasswordInput.reportValidity();
            return;
        }

        resetValidationText();

        const formData = {
            OiriginTrace: oiriginTrace,
            VenueKey: venueKey,
            EmailAddress: emailInput.value,
            FirstName: document.getElementById('firstName').value,
            Surname: document.getElementById('surname').value,
            Password: passwordInput.value
        };

        fetch(`${baseUrl}/api/users/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Failed to create account');
                }
            })
            .then(data => {
                console.log('Success:', data);
                // Handle success (e.g., redirect to another page or show a success message)
                navigate('/acitvate-account');

            })
            .catch((error) => {
                console.error('Error:', error);
                setError('Failed to create account');
                // Handle error (e.g., show an error message)
            });
    }

    function matchValue(v1, v2) {
        return v1 === v2;
    }

    return (
    <Container sx = {{width : "400px"}}>
        <CssBaseline />
        <AccountSetupStepper currentStep={0} />
        {error && <Alert severity="error">{error}</Alert>}
       <form size="medium" sx={{ width: "350px" }} onSubmit={handleSubmit}>
            <TextField type="hidden" value={oiriginTrace} hidden={true} />
            <TextField disabled={true} label="Venue Key" type="text" fullWidth margin="normal" value={venueKey} variant="filled" />
            <TextField
                id="email"
                label="Email address"
                type="email"
                variant="standard"
                required
                error={emailValidationText !== ''}
                helperText={emailValidationText}
                fullWidth
                margin="normal"
            />
            <TextField id="firstName" label="First name" type="text" fullWidth margin="normal" variant="standard" />
            <TextField id="surname" label="Surname" type="text" fullWidth margin="normal" variant="standard" />
            <TextField id="pwd" label="Password" required type="password" fullWidth margin="normal" variant="standard" />
            <TextField
                id="confirmPwd"
                label="Confirm Password" required type="password" fullWidth margin="normal" variant="standard"
                error={passwordValidationText !== ''}
                helperText={passwordValidationText}
            />
            <Button type="submit" variant="contained" color="primary">Create Account</Button>
            <Typography variant="h4" gutterBottom>Create Account</Typography>
        </form>
    </Container>
  );
};

export default CreateAccount;
