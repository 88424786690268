import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Alert } from '@mui/material';
import config from '../config';
import Helper from '../helpers';

const baseUrl = config.apiBaseUrl;

const Profile = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const userId = await Helper.getCookie('wh.user.id');
                const response = await fetch(`${baseUrl}/api/users/user/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user information');
                }
                const data = await response.json();
                setName(data.name);
                setEmail(data.email);
            } catch (error) {
                setError('Failed to load user information');
            }
        };

        fetchUserInfo();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        try {
            const userId = await Helper.getCookie('wh.user.id');
            const response = await fetch(`${baseUrl}/api/users/user/${userId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, email })
            });

            if (!response.ok) {
                throw new Error('Failed to update user information');
            }

            setSuccess('Profile updated successfully');
        } catch (error) {
            setError('Failed to update profile');
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}
        >
            <Typography variant="h4" gutterBottom>Profile</Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                fullWidth
            />
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                fullWidth
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                <Button type="submit" variant="outlined" color="primary">Update Profile</Button>
            </Box>
        </Box>
    );
};

export default Profile;
