import React, { useMemo} from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CancelIcon from "@mui/icons-material/Cancel";
import config from "../config";
import Helper from "../helpers";

const baseUrl = config.apiBaseUrl;
const ConfirmCancelSession = ({handleClose,Values}) => {
    let title = Values.title;
    let sessionId = Values.sessionId;
    //let sessionTime = new Date(Values.sessionDateTime);
    const sessionTime = useMemo(() => new Date(Values.sessionDateTime), [Values.sessionDateTime]);

    const [message, setMessage] = React.useState("");
    const handleCancelSession = async () => {
        const token = await Helper.getCookie('wh-jwt');
        const response = await fetch(`${baseUrl}/api/book/${sessionId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            credentials: 'include'
        });
        if (response.ok) {
            handleClose();
            window.location.reload();
        }

    };

    useMemo(() => {
        const now = new Date();
        const timeDiff = sessionTime - now;
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        if (hoursDiff < 24) {
            setMessage("You will lose the token for this session as it is within 24 hours.");
        }
    }, [sessionTime]);

    return (
        <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: '4px', maxWidth: '400px', margin: 'auto' }}>
            <Typography variant="h6" component="h2">Cancel Session</Typography>
            <Typography variant="body1" component="p">Are you sure you want to cancel the session <strong>{title}</strong>?</Typography>
            {message && <Typography variant="body2" color="error">{message}</Typography>}
            <Button variant="contained" color="error" onClick={handleCancelSession} sx={{ mt: 2 }} endIcon={<CancelIcon/>}>
                Cancel Session
            </Button>
        </Box>
    );
}

export default ConfirmCancelSession;
