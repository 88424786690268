import React, { useEffect, useState } from 'react';
import { Drawer, Avatar } from '@mui/material';
import MenuContent from "./compenents/MenuContent";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Helper from "../helpers";


const drawerWidth = 240;

const Sidebar = () => {
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

    useEffect(() => {
        const fetchUserInfo = async () => {
            const email = await Helper.getCookie('wh.user.email');
            await Helper.getCookie('wh.user.id');
            setUsername(email);
            setIsLoggedIn(!!email);
            setLoading(false);
            setName("");
        };

        fetchUserInfo();
    }, []);

    if (loading) {
        return <div id="overlay">Loading...</div>;
    }

    const drawerContent = (
        <>
            <MenuContent />
            <Stack
                direction="row"
                sx={{
                    p: 2,
                    gap: 1,
                    alignItems: 'center',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Avatar
                    sizes="small"
                    alt={username}
                    src="/static/images/avatar/7.jpg"
                    sx={{ width: 36, height: 36 }}
                />
                <Box sx={{ mr: 'auto' }}>
                    <Typography variant="body2" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                        {name}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {username}
                    </Typography>
                </Box>
            </Stack>
        </>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            {isDesktop && isLoggedIn && (
                <Drawer
                    variant="permanent"
                    open
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    }}
                >
                    {drawerContent}
                </Drawer>
            )}
        </Box>
    );
};

export default Sidebar;
