import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import  NavMenu  from "./components/NavMenu";
import {darkTheme} from './theme';
import { ThemeProvider } from "@mui/material";
import Login from './pages/Login';
import PrivateRoute from './routing/PrivateRoute';
import Dashboard from "./dashboard/Dashboard";
import CreateAccount from "./pages/CreateAccount";
// import Membership from "./pages/Membership";
import AcitvateAccount from "./pages/AcitvateAccount";
import WaitingListForm from "./pages/WaitingListForm";
import ConfirmEmail from "./pages/ConfirmEmail";

const App = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';
  const isWaitingList = location.pathname === '/waiting-list';

  return (
    <ThemeProvider theme={darkTheme}>
      <main>
        {(!isHomePage && !isWaitingList) && <NavMenu />}
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/create-account" element={<CreateAccount />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/acitvate-account" element={<AcitvateAccount />} />
            <Route path="/dashboard/*" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/waiting-list" element={<WaitingListForm />} />
            {AppRoutes.map((route, index) => {
              const { element, private: isPrivate, ...rest } = route;
              return (
                  <Route
                      key={index}
                      {...rest}
                      element={isPrivate ? <PrivateRoute element={element} /> : element}
                  />);
            })}
          </Routes>
        </Layout>
      </main>
    </ThemeProvider>
  );
};

export default App;
